<template>
  <client-only>
    <OsButton
      :aria-label="isPersonalised ? 'Search' : 'Accept & Search'"
      class="postcode-prefilter__button"
      v-bind="$attrs"
      @click="submit"
    >
      <OsIcon name="Cart" />
      {{ isPersonalised ? 'Search' : 'Accept & Search' }}
    </OsButton>
  </client-only>
</template>

<script lang="ts">
import { useFinanceCriteriaStore } from '~/stores/finance/criteriaStore'

export default {
  name: 'SearchWithFinanceCta',

  setup() {
    const financeCriteriaStore = useFinanceCriteriaStore()

    const submit = () => {
      financeCriteriaStore.hasAccepted = true
    }

    const isPersonalised = computed(() => financeCriteriaStore.isPersonalised)

    return {
      submit,
      isPersonalised
    }
  }
}
</script>
